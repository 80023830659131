.blog-item {
  height: 300px; /* Adjust as needed */
  width: 100%;
  display: flex;
  flex-direction: column;
}

.blog-item img {
  width: 100%;
  height: 200px; /* Adjust as needed */
  object-fit: cover; /* Ensures that images are resized and cropped */
}
